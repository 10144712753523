import { useIsMobile } from '@/hooks/useIsDesktop';
import { gaClickEvent } from '@/utils/ga';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import * as S from './style';

const MainSection = () => {
  const [device, setDevice] = useState<string | null>(null);
  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const buttonDevice = useIsMobile() ? 'mobile' : 'web';

  useEffect(() => {
    const randomSrc = Math.floor(Math.random() * 2) ? 'runway' : 'sortie';
    if (!device) {
      setDevice(window.innerWidth <= 1280 ? 'mobile' : 'web');
    } else {
      device === 'web'
        ? setVideoSrc(
            `${process.env.NEXT_PUBLIC_RESOURCES_URL}/videos/home/main/${randomSrc}`,
          )
        : setVideoSrc(
            `${process.env.NEXT_PUBLIC_RESOURCES_URL}/videos/home/main/sortie`,
          );
    }
  }, [device]);

  return (
    // <S.StyledSection isVideoSrcReady={true}>
    //   <S.StyledVideo autoPlay loop muted playsInline preload="metadata">
    //     <>
    //       <source src="videos/home/main/sortie.webm" type="video/webm" />
    //       <source src="videos/home/main/sortie.mp4" type="video/mp4" />
    //     </>
    //   </S.StyledVideo>
    //   <S.MarketButtonContainer animationStart={true}>
    //     <S.MarketButtonCanvas>
    //       <Image
    //         src={`${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/main/${buttonDevice}/app_storelogo_btn.webp`}
    //         alt="App store link image"
    //         fill
    //       />
    //     </S.MarketButtonCanvas>
    //     <S.MarketButtonCanvas>
    //       <Image
    //         src={`${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/main/${buttonDevice}/google_storelogo_btn.webp`}
    //         alt="App store link image"
    //         fill
    //       />
    //     </S.MarketButtonCanvas>
    //     <S.MarketButtonCanvas>
    //       <Image
    //         src={`${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/main/${buttonDevice}/one_storelogo_btn.webp`}
    //         alt="App store link image"
    //         fill
    //       />
    //     </S.MarketButtonCanvas>
    //   </S.MarketButtonContainer>
    // </S.StyledSection>
    <S.StyledSection isVideoSrcReady={videoSrc ? true : false}>
      <S.StyledVideo autoPlay loop muted playsInline preload="metadata">
        {videoSrc && (
          <>
            {/* TODO: CDN으로 주소 변경 */}
            <source src={`${videoSrc}.webm`} type="video/webm" />
            <source src={`${videoSrc}.mp4`} type="video/mp4" />
          </>
        )}
      </S.StyledVideo>
      {device && (
        <S.MarketButtonContainer animationStart={videoSrc ? true : false}>
          <S.MarketButtonCanvas
            onClick={() => {
              gaClickEvent({
                action: 'select_store',
                params: { category: 'app-store' },
              });
            }}
          >
            <Link
              href="https://apps.apple.com/kr/app/id1496742556"
              target="_blank"
            >
              <Image
                src={`${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/main/${buttonDevice}/app_storelogo_btn.webp`}
                alt="App store link image"
                fill
              />
            </Link>
          </S.MarketButtonCanvas>
          <S.MarketButtonCanvas
            onClick={() => {
              gaClickEvent({
                action: 'select_store',
                params: { category: 'google-store' },
              });
            }}
          >
            <Link
              href="https://play.google.com/store/apps/details?id=com.clovergames.lordofheroes"
              target="_blank"
            >
              <Image
                src={`${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/main/${buttonDevice}/google_storelogo_btn.webp`}
                alt="App store link image"
                fill
              />
            </Link>
          </S.MarketButtonCanvas>
          <S.MarketButtonCanvas
            onClick={() => {
              gaClickEvent({
                action: 'select_store',
                params: { category: 'one-store' },
              });
            }}
          >
            <Link
              href="https://m.onestore.co.kr/mobilepoc/apps/appsDetail.omp?prodId=0000762663"
              target="_blank"
            >
              <Image
                src={`${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/main/${buttonDevice}/one_storelogo_btn.webp`}
                alt="App store link image"
                fill
              />
            </Link>
          </S.MarketButtonCanvas>
        </S.MarketButtonContainer>
      )}
    </S.StyledSection>
  );
};

export default MainSection;
