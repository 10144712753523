import { keyframes } from 'styled-components';

export const slideLeft = keyframes`
    from {
        left: 100%;
    }
    to {
        left: -180%;
    }
`;

export const arrowDown = keyframes`
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0);
    }
`;

export const scaleDown = keyframes`
    from {
        opacity: 0;
        transform: scale(2.5);
    }
    to {
        opacity: 1;
        transform: scale(1.0);
    }
`;

export const spin = keyframes`
    to { 
        transform: rotate(360deg);
    }
`;

export const rotation = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;
