import { scaleDown } from '@/utils/keyframe';
import styled, { css } from 'styled-components';

export const StyledSection = styled.section<{ isVideoSrcReady: boolean }>`
  width: 100%;
  height: calc(100vh - 4.25rem);
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  opacity: 0;
  transition: opacity 0.5s ease;
  ${({ isVideoSrcReady }) =>
    isVideoSrcReady &&
    css`
      opacity: 1;
    `}
`;

export const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const MarketButtonContainer = styled.ul<{ animationStart: boolean }>`
  position: absolute;
  width: 35.125rem;
  height: 3.5rem;
  bottom: 48px;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.tablet} {
    bottom: 10rem;
  }
  ${({ theme }) => theme.mobile} {
    width: 26rem;
    height: 6rem;
    bottom: 15rem;
  }
  ${({ animationStart }) =>
    animationStart &&
    css`
      li:nth-child(1) {
        animation-delay: 0s;
      }
      li:nth-child(2) {
        animation-delay: 0.15s;
      }
      li:nth-child(3) {
        animation-delay: 0.3s;
      }
    `}
`;

export const MarketButtonCanvas = styled.li`
  width: 10.6875rem;
  height: 3.5rem;
  position: relative;
  animation: ${scaleDown} 0.5s ease forwards;
  opacity: 0;
  ${({ theme }) => theme.mobile} {
    width: 6rem;
    height: 6rem;
  }
`;
